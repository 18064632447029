/**
 * @file Manages styles for Contact Us.
 * @author kkumari
 */
import { css } from 'styled-components';
import breakPoints from '../../../themes/breakpoints';
export default css(["padding:0 20px;background-color:var(--sc-blog-list--bg-color,", ");.back-link{display:none;}h1{margin-bottom:30px;margin-top:15px;color:var(--sc-blog-list--color-heading,", ");}h4{margin-bottom:16px;color:var(--sc-blog-list--color-sub-heading,", ");@media (min-width:", "){margin-bottom:32px;}@media (min-width:", "){margin-bottom:60px;}}p{color:var(--sc-blog-list--color-paragraph,", ");}.image-wrapper{margin-bottom:25px;overflow:hidden;", ";}.blog-content{padding-bottom:30px;p:last-child{margin-bottom:0;}@media (min-width:", "){padding-bottom:80px;}@media (min-width:", "){padding:0 110px 80px;}}@media (min-width:", "){padding:0 115px;}@media (min-width:", "){padding:0;}"], function (props) {
  var _props$theme, _props$theme$blogList;
  return (_props$theme = props.theme) === null || _props$theme === void 0 ? void 0 : (_props$theme$blogList = _props$theme.blogList) === null || _props$theme$blogList === void 0 ? void 0 : _props$theme$blogList.bgColor;
}, function (props) {
  var _props$theme2, _props$theme2$blogLis;
  return (_props$theme2 = props.theme) === null || _props$theme2 === void 0 ? void 0 : (_props$theme2$blogLis = _props$theme2.blogList) === null || _props$theme2$blogLis === void 0 ? void 0 : _props$theme2$blogLis.headingColor;
}, function (props) {
  var _props$theme3, _props$theme3$blogLis;
  return (_props$theme3 = props.theme) === null || _props$theme3 === void 0 ? void 0 : (_props$theme3$blogLis = _props$theme3.blogList) === null || _props$theme3$blogLis === void 0 ? void 0 : _props$theme3$blogLis.subHeadingColor;
}, breakPoints.tabletP, breakPoints.desktop, function (props) {
  var _props$theme4, _props$theme4$blogLis;
  return (_props$theme4 = props.theme) === null || _props$theme4 === void 0 ? void 0 : (_props$theme4$blogLis = _props$theme4.blogList) === null || _props$theme4$blogLis === void 0 ? void 0 : _props$theme4$blogLis.paraColor;
}, function (props) {
  var _props$theme5, _props$theme5$blogLis, _props$theme6, _props$theme6$blogLis;
  return (_props$theme5 = props.theme) !== null && _props$theme5 !== void 0 && (_props$theme5$blogLis = _props$theme5.blogList) !== null && _props$theme5$blogLis !== void 0 && _props$theme5$blogLis.blogTileImageRadius ? "border-radius: var(--sc-blog-list--border-radius-blog-tile-image, ".concat((_props$theme6 = props.theme) === null || _props$theme6 === void 0 ? void 0 : (_props$theme6$blogLis = _props$theme6.blogList) === null || _props$theme6$blogLis === void 0 ? void 0 : _props$theme6$blogLis.blogTileImageRadius, ");") : '';
}, breakPoints.desktop, breakPoints.desktopM, breakPoints.tabletP, breakPoints.desktop);