export var imageRenditions = {
  desktopL: {
    w: '1252',
    h: '564'
  },
  desktop: {
    w: '1070',
    h: '482'
  },
  tabletL: {
    w: '964',
    h: '482'
  },
  tabletP: {
    w: '540',
    h: '370'
  },
  mobile: {
    w: '375',
    h: '250'
  }
};