import { css } from 'styled-components';
import breakPoints from '../../../themes/breakpoints';
import { imageRenditions } from './config';
var Styles = css(["background-color:var(--sc-service--bg-color,", ");.back-link{display:none;}.backLink{display:none;}.serviceName{padding:var(--sc-service--name-padding,", ");@media (min-width:", "){padding:var(--sc-spacing-7,40px) 0;h1{font-size:var(--sc-font-size-h1,", ");line-height:var(--sc-line-height-h1,", ");}}@media (min-width:", ") and (max-width:", "){padding:var(--sc-service--name-padding_mobile,", ");}}hr{background-color:var(--sc-service--divider-color,", ");margin:var(--sc-spacing-5,30px) var(--sc-spacing-4,20px) var(--sc-spacing-7,40px);@media (min-width:", "){margin:var(--sc-spacing-7,40px) var(--sc-spacing-16,114px);}@media (min-width:", "){margin:var(--sc-spacing-7,40px) 0;}}.serviceGallery{margin-bottom:var(--sc-service--gallery-margin-bottom,", ");@media (min-width:", ") and (max-width:", "){padding:0 var(--sc-spacing-16,114px);}}.slick-dots{bottom:-45px;}.slick-arrow{width:39px;height:78px;}.serviceOverview{padding:var(--sc-service--overview-padding,", ");@media (min-width:", "){padding:var(--sc-service--overview-padding_mobile,", ");}@media (min-width:", ") and (max-width:", "){padding:0 var(--sc-spacing-16,114px);}}.serviceDetails{padding:var(--sc-spacing-5,26px) var(--sc-spacing-4,20px) var(--sc-spacing-7,40px);ul{margin-bottom:1.45rem;}h2{margin:0;padding-bottom:var(--sc-spacing-4,20px);}@media (min-width:", ") and (max-width:", "){padding:var(--sc-spacing-5,24px) var(--sc-spacing-16,114px);}@media (min-width:", "){padding:var(--sc-service--details-padding,", ");}}.slick-slide{overflow:hidden;", " ", "}"], function (props) {
  var _props$theme, _props$theme$service;
  return (_props$theme = props.theme) === null || _props$theme === void 0 ? void 0 : (_props$theme$service = _props$theme.service) === null || _props$theme$service === void 0 ? void 0 : _props$theme$service.bgColor;
}, function (props) {
  var _props$theme2, _props$theme2$service;
  return ((_props$theme2 = props.theme) === null || _props$theme2 === void 0 ? void 0 : (_props$theme2$service = _props$theme2.service) === null || _props$theme2$service === void 0 ? void 0 : _props$theme2$service.serviceNamePadding) || '0 20px';
}, breakPoints.desktop, function (props) {
  var _props$theme3;
  return (_props$theme3 = props.theme) === null || _props$theme3 === void 0 ? void 0 : _props$theme3.fontSizeH1;
}, function (props) {
  var _props$theme4;
  return (_props$theme4 = props.theme) === null || _props$theme4 === void 0 ? void 0 : _props$theme4.lineHeightH1;
}, breakPoints.tabletP, breakPoints.tabletL, function (props) {
  var _props$theme5, _props$theme5$service;
  return ((_props$theme5 = props.theme) === null || _props$theme5 === void 0 ? void 0 : (_props$theme5$service = _props$theme5.service) === null || _props$theme5$service === void 0 ? void 0 : _props$theme5$service.serviceNameMobilePadding) || '0 114px';
}, function (props) {
  var _props$theme6, _props$theme6$service;
  return (_props$theme6 = props.theme) === null || _props$theme6 === void 0 ? void 0 : (_props$theme6$service = _props$theme6.service) === null || _props$theme6$service === void 0 ? void 0 : _props$theme6$service.borderColor;
}, breakPoints.tabletP, breakPoints.desktop, function (props) {
  var _props$theme7, _props$theme7$service;
  return ((_props$theme7 = props.theme) === null || _props$theme7 === void 0 ? void 0 : (_props$theme7$service = _props$theme7.service) === null || _props$theme7$service === void 0 ? void 0 : _props$theme7$service.serviceGalleryMarginBottom) || '40px';
}, breakPoints.tabletP, breakPoints.tabletL, function (props) {
  var _props$theme8, _props$theme8$service;
  return ((_props$theme8 = props.theme) === null || _props$theme8 === void 0 ? void 0 : (_props$theme8$service = _props$theme8.service) === null || _props$theme8$service === void 0 ? void 0 : _props$theme8$service.serviceOverviewPadding) || '0 20px 40px';
}, breakPoints.desktop, function (props) {
  var _props$theme9, _props$theme9$service;
  return ((_props$theme9 = props.theme) === null || _props$theme9 === void 0 ? void 0 : (_props$theme9$service = _props$theme9.service) === null || _props$theme9$service === void 0 ? void 0 : _props$theme9$service.serviceOverviewMobilePadding) || '0 218px 40px';
}, breakPoints.tabletP, breakPoints.tabletL, breakPoints.tabletP, breakPoints.tabletL, breakPoints.desktop, function (props) {
  var _props$theme10, _props$theme10$servic;
  return ((_props$theme10 = props.theme) === null || _props$theme10 === void 0 ? void 0 : (_props$theme10$servic = _props$theme10.service) === null || _props$theme10$servic === void 0 ? void 0 : _props$theme10$servic.serviceDetailsPadding) || '24px 218px 60px';
}, function (props) {
  var _props$theme11, _props$theme11$servic;
  return (_props$theme11 = props.theme) !== null && _props$theme11 !== void 0 && (_props$theme11$servic = _props$theme11.service) !== null && _props$theme11$servic !== void 0 && _props$theme11$servic.cardRadius ? "border-radius: 10px;" : '';
}, function (props) {
  var _props$theme12, _props$theme12$servic;
  return (_props$theme12 = props.theme) !== null && _props$theme12 !== void 0 && (_props$theme12$servic = _props$theme12.service) !== null && _props$theme12$servic !== void 0 && _props$theme12$servic.addSidePaddingToServiceDetailImageOnMobile ? "@media (max-width: ".concat(breakPoints.mobile, ") {\n      padding: 0 var(--sc-spacing-3, 15px);\n    }") : '';
});
export default Styles;