export var imageRenditions = {
  desktopL: {
    w: '1252',
    h: '678'
  },
  desktop: {
    w: '1070',
    h: '578'
  },
  tabletL: {
    w: '964',
    h: '502'
  },
  tabletP: {
    w: '540',
    h: '280'
  },
  mobile: {
    w: '280',
    h: '146'
  }
};